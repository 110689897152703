import { Link, graphql, navigate } from 'gatsby';
import React, { useState } from 'react';
import BodyClassName from 'react-body-classname';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import SocialShare from '../../../../components/Shop/SocialShare';
import DefaultLayoutComponent from '../../../layouts/DefaultLayoutComponent';
import ReadPageContent from '../../../../components/Read/ReadPageContent';
import { getEventDate, getVideoThumbnail } from '../../../../utils/functions';
import { getSliceNumber } from '../../../../utils/podCast';
import RelatedContent from '../../../../components/Watch/RelatedContent';
import Loadable from 'react-loadable';
import { formatTitle } from '../../../../utils/shop';
import ArrowLeft from '../../../../images/icon-arrow-left.svg';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

const isListEmpty = (arr) => {
  if (Array.isArray(arr) && !!arr?.length) {
    return false;
  }

  return true;
};

export function EventSingleRender({ event, relatedContent, relatedEvent }) {
  var seoData = {
    canonical: event?.uri,
    title: event?.title.text,
    metaDesc: event?.metaDescriptions?.text || `Read more about ${event?.title.text}`,
    opengraphDescription: event?.metaDescriptions?.text || `Read more about ${event?.title.text}`,
    twitterDescription: event?.metaDescriptions?.text || `Read more about ${event?.title.text}`,
    opengraphTitle: event?.title.text || '',
    twitterTitle: event?.title.text || '',
    opengraphImage: {
      sourceUrl: event?.featuredImage?.node?.sourceUrl,
    },
    twitterImage: {
      sourceUrl: event?.featuredImage?.node?.sourceUrl,
    },
    opengraphUrl: event?.uri || '',
  };

  if (!event) {
    return null;
  }

  const { title, postBanner } = event;

  const nextEvent = relatedEvent.find((item) => item.isNext);
  const prevEvent = relatedEvent.find((item) => !item.isNext);

  return (
    <BodyClassName className="body-dark page-read page-read--single">
      <DefaultLayoutComponent seo={seoData} description={event?.seo?.metaDesc} timeline title="Timeline">
        <div className="site-main">
          <div className="read-body bg-transparent">
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="section r_s_title bg-light pd-t-0">
                <div className="cross_icon" onClick={() => navigate(-1)}>
                  <img src={ArrowLeft} alt="back-btn" />
                </div>
                <div className="wrapper wrapper-lg pd-x-sm">
                  <div className="wrapper wrapper-md post-title pd-x-sm">
                    <div className="event-tags">
                      <span className="event-tags-item">Event</span>
                      {event.relatedPeople.map((item) => (
                        <span className="event-tags-item mg-l-4">{item.relatedPerson.uid.replace(/-/g, ' ')}</span>
                      ))}
                      {event.relatedPlaces.map((item) => (
                        <span className="event-tags-item mg-l-4">{item.places.uid}</span>
                      ))}
                    </div>
                    <h3 className="sectionHeading mg-b-0">{title.text}</h3>
                    <p className="event-date">{getEventDate(event)}</p>
                  </div>
                </div>
              </div>
              {!!postBanner?.fluid?.src && (
                <div
                  className="section r_s_banner bgImage bg-light pd-0"
                  style={{
                    backgroundImage: `url(${postBanner?.fluid?.src})`,
                  }}
                />
              )}
            </div>
          </div>
          <ReadPageContent
            body={event?.body}
            featuredVideo={event?.featuredVideo}
            postBanner={postBanner?.fluid?.src}
          />

          <div className="read-footer bg-transparent">
            <div className="wrapper wrapper-xl pd-x-md">
              <div className="section pd-b-0 r_s_footer-info pd-t-0 pd-x-sm">
                <div className="wrapper wrapper-md pd-x-sm">
                  <div className="related_title pd-x-sm">Related</div>

                  <div className="d-flex columns-4 ">
                    {relatedContent.map((ep) => (
                      <RelatedContent {...ep} categoryColor={'#0A0A0A'} titleColor={'#0A0A0A'} />
                    ))}
                  </div>

                  <div className="wrapper-sm">
                    <SocialShare title={event.title.text} />
                  </div>
                </div>
              </div>
              {relatedEvent.length && (
                <div className="bg-light event-navigation">
                  <div className="bordered-element">
                    {prevEvent && (
                      <Link to={prevEvent.url}>
                        <div className="event-item">
                          <div className="event-arrow">
                            <ArrowBackOutlinedIcon />
                          </div>
                          <div className="detail">
                            <p className="event-title">Event</p>
                            <p className="event-subtitle">{prevEvent.title}</p>
                          </div>
                        </div>
                      </Link>
                    )}
                  </div>
                  <div className="bordered-element">
                    {nextEvent && (
                      <Link to={nextEvent.url}>
                        <div className="event-item">
                          <div className="detail">
                            <p className="event-title">Event</p>
                            <p className="event-subtitle">{nextEvent.title}</p>
                          </div>
                          <div className="event-arrow">
                            <ArrowForwardOutlinedIcon />
                          </div>
                        </div>
                      </Link>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
}

function EventSinglePage(props) {
  const {
    data: {
      event: { nodes },
      totalWatchData,
      readData,
      allEventLists,
      podCastData,
    },

    pageContext: { uid },
  } = props;

  let singleEventReadData = [];
  if (Array.isArray(nodes)) {
    singleEventReadData = nodes[0];
  }
  const { relatedEvents, relatedEpisodes, relatedArticles, relatedProducts, relatedListen } = singleEventReadData.data;

  const getEventIndexAtArray = allEventLists.nodes.findIndex((e) => e.uid === uid);

  const eventsMapped =
    getEventIndexAtArray !== -1
      ? getEventIndexAtArray === 0
        ? [{ ...allEventLists.nodes[1], isNext: true }]
        : getEventIndexAtArray === allEventLists.totalCount - 1
        ? [{ ...allEventLists.nodes[getEventIndexAtArray - 1], isNext: false }]
        : [
            { ...allEventLists.nodes[getEventIndexAtArray - 1], isNext: false },
            { ...allEventLists.nodes[getEventIndexAtArray + 1], isNext: true },
          ]
      : [];
  const events = eventsMapped.map((mappedEvent) => {
    return {
      title: mappedEvent.data.title.text,
      url: `/timeline-details/event/${mappedEvent.uid}`,
      isNext: mappedEvent.isNext,
    };
  });

  const listen = relatedListen?.map((item) => {
    const pod = podCastData.nodes.find((pod) => pod.uid === item.podcast.uid);
    return {
      thumbnailUrl: pod.data.thumnailImage.url,
      category: 'LISTEN',
      title: pod.data.podCastTitle[0].text,
      url: `/podcast/${pod.uid}`,
    };
  });

  const read = relatedArticles
    ?.filter((content) => content.reads?.uid)
    .map((item) => {
      const read = readData.nodes.find((read) => read.uid === item.reads?.uid);
      return {
        thumbnailUrl: read.data.featuredImage.url,
        category: 'READ',
        title: read.data.title.text,
        url: read.url,
      };
    });

  const watch = relatedEpisodes
    ?.filter((content) => content?.episodes?.uid)
    .map((item) => {
      const watch = totalWatchData.nodes.find((watch) => watch.uid === item.episodes.uid);
      return {
        thumbnailUrl: watch.data.thumbnailImage.url || getVideoThumbnail(watch.data.youtubeId[0].text),
        category: 'WATCH',
        title: watch.data.title[0].text,
        url: `/video_episodes/${watch.uid}`,
      };
    });

  const product = relatedProducts
    ?.filter((p) => p?.relatedProduct)
    .map((prod) => {
      return {
        thumbnailUrl: prod.relatedProduct.image.src,
        category: 'SHOP',
        title: prod.relatedProduct.title,
        url: `/product/${formatTitle(prod.relatedProduct.title)}`,
      };
    });
  const relatedReadCount = read.length;
  const relatedEpisodesCount = watch.length;
  const relatedShopCount = product.length;
  const relatedListenCount = listen.length;

  let relatedData = [];

  if (watch.length) {
    const sliceNumber = getSliceNumber(relatedEpisodesCount, relatedShopCount, relatedListenCount, relatedData);
    relatedData = relatedData.concat(watch.slice(0, sliceNumber));
  }
  if (read.length) {
    const sliceNumber = getSliceNumber(relatedReadCount, relatedShopCount, relatedListenCount, relatedData);
    relatedData = relatedData.concat(read.slice(0, sliceNumber));
  }

  if (product.length) {
    const sliceNumber = getSliceNumber(relatedReadCount, relatedEpisodesCount, relatedListenCount, relatedData);
    relatedData = relatedData.concat(product.slice(0, sliceNumber));
  }

  if (relatedListenCount) {
    const sliceNumber = getSliceNumber(relatedReadCount, relatedEpisodesCount, relatedShopCount, relatedData);
    relatedData = relatedData.concat(listen.slice(0, sliceNumber));
  }

  return <EventSingleRender event={singleEventReadData?.data} relatedContent={relatedData} relatedEvent={events} />;
}

export const query = graphql`
  query SingleEventPageQuery($uid: String!) {
    event: allPrismicEvents(filter: { uid: { eq: $uid } }) {
      nodes {
        data {
          title {
            text
          }
          postBanner: post_banner {
            fluid(maxWidth: 1400) {
              ...GatsbyPrismicImageFluid
            }
          }
          body {
            ... on PrismicEventsBodyText {
              id
              primary {
                backgroundColor: background_color
                sectionContents: section_contents {
                  text: html
                }
              }
              sliceType: slice_type
            }
            ... on PrismicEventsBodyPhoto {
              id
              items {
                image {
                  alt
                  fluid(maxWidth: 1200) {
                    ...GatsbyPrismicImageFluid
                  }
                }
              }
              sliceType: slice_type
              primary {
                backgroundColor: background_color
              }
            }
            ... on PrismicEventsBodyVideo {
              id
              primary {
                backgroundColor: background_color
                videoId: video_id {
                  text
                }
              }
              sliceType: slice_type
            }
            ... on PrismicEventsBodyQuote {
              id
              primary {
                backgroundColor: background_color
                sectionContents: section_contents {
                  text
                }
              }
              sliceType: slice_type
            }
          }
          relatedListen: related_listen {
            podcast {
              uid
              url
              type
            }
          }

          relatedEpisodes: related_episodes {
            episodes {
              type
              uid
            }
          }
          relatedArticles: related_articles {
            reads {
              type
              url
              uid
            }
          }
          relatedProducts: related_products {
            relatedProduct: related_product
          }
          relatedPeople: related_people {
            relatedPerson: related_person {
              uid
              type
            }
          }
          relatedPlaces: related_places {
            places {
              uid
              type
            }
          }
          event_end_date
          event_year
          date_accuracy
          date_type
        }
        id
        url
        last_publication_date
        first_publication_date
      }
    }
    readData: allPrismicRead(sort: { fields: data___priority }) {
      nodes {
        data {
          title {
            text
          }
          featuredImage: featured_image {
            alt
            url
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        url
        uid
      }
    }
    totalWatchData: allPrismicEpisodes(sort: { fields: data___priority, order: ASC }) {
      nodes {
        data {
          title {
            text
          }
          thumbnailImage: thumbnail_image {
            url
          }
          youtubeId: youtube_id {
            text
          }
        }
        uid
      }
    }
    allEventLists: allPrismicEvents(sort: { fields: data___event_year, order: ASC }) {
      nodes {
        data {
          title {
            text
          }
        }
        uid
        type
      }
      totalCount
    }
    podCastData: allPrismicPodcast(sort: { fields: first_publication_date, order: DESC }) {
      nodes {
        data {
          thumnailImage: thumbnail_image {
            url
          }
          podCastTitle: podcast_title {
            text
          }
          season {
            type
            uid
          }
          podcastDuration: podcast_duration
        }
        uid
      }
    }
  }
`;

export default EventSinglePage;
